import React from 'react'
import PropTypes from 'prop-types'

import Layout from '../components/layout'
import SEO from '../components/seo'

import SCADLogo from '../images/logo_scad.png'
import HusqvarnaLogo from '../images/logo_husqvarna.png'
import BestwayLogo from '../images/logo_bestway.png'
import PhilipsLogo from '../images/logo_philips.png'
import SiemensLogo from '../images/logo_siemens.png'
import MotorolaLogo from '../images/logo_motorola.png'
import Process1 from '../images/process_1.png'
import Process2 from '../images/process_2.png'
import Process3 from '../images/process_3.png'
import Process4 from '../images/process_4.png'

const AboutPage = () => (
  <Layout>
    <SEO title="About"></SEO>
    <div>
      <div className="min-h-[651px] bg-about-me-background bg-cover bg-[position:50%_100%] bg-no-repeat text-white">
        <div className="p-[5vw] md:m-0 md:mx-auto md:flex md:min-h-[651px] md:max-w-5xl md:items-center md:justify-start md:overflow-hidden lg:p-0">
          <div className="pl-4 md:w-[60%]">
            <h2 className="m-0 mb-[60px] text-5xl font-normal">Meet Julie</h2>
            <p className="mb-4 text-xl leading-normal">I am a UX designer who enjoys crafting user experiences for mobile and web.</p>

            <p className="text-xl leading-normal">
              As a user experience/interaction designer, I am truely passionate about creating design concepts that initiate meaningful
              connection points between people and the product. My job includes to be an ambassador to the user, developing amazing design
              solutions that delight our customers by solving important customer problems end-to-end, crafting an experience that balances
              the needs of the business and while delivering a useful and intuitive and engaging product experience for our users.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-[color:#e6eaeb] py-[60px] px-0">
        <h2 className="text mt-0 py-[30px] px-0 text-center text-[2rem] font-normal text-neutral-700 before:py-0 before:px-[10px] before:content-['-'] after:py-0 after:px-[10px] after:content-['-']">
          My Journey
        </h2>
        <ul className="relative m-0 flex h-80 list-none justify-center p-0 text-neutral-700 before:absolute before:left-0 before:top-[50%] before:z-[5] before:block before:h-[6px] before:w-full before:bg-[#bfa288] before:content-['_']">
          <TimelinePointListItem period="2009.09 - 2040.06" event="Studied Industrial Design" logo={SCADLogo} />
          <TimelinePointListItem
            period="2010.06 - 2010.12"
            event="Worked as an
              <br />
              Industrial Designer"
            logo={HusqvarnaLogo}
          />
          <TimelinePointListItem period="2011.01 - 2012.06" event="Studied Interactive Design" logo={SCADLogo} />
          <TimelinePointListItem period="2011.06 - 2011.08" event="Worked as a Designer" logo={BestwayLogo} />
          <TimelinePointListItem
            period="2011.09 - 2011.12"
            event="Worked as an
              <br />
              Interaction Design Intern"
            logo={PhilipsLogo}
          />
          <TimelinePointListItem
            period="2012.06 - 2012.10"
            event="Worked as an
              <br />
              Interaction Design Intern"
            logo={SiemensLogo}
          />
          <TimelinePointListItem
            period="2012.10 - current"
            event="Worked as a
              <br />
              Senior UX Designer"
            logo={MotorolaLogo}
          />
        </ul>
      </div>
      <div className="skills bg-white py-[60px] px-0">
        <h2 className="mt-0 py-[30px] px-0 text-center text-[2rem] font-normal text-neutral-700 before:py-0 before:px-[10px] before:content-['-'] after:py-0 after:px-[10px] after:content-['-']">
          My Skills
        </h2>
        <div className="flex flex-wrap text-neutral-700 md:my-0 md:mx-auto md:max-w-5xl">
          <div className="flex flex-[1_1_512px] flex-col items-center py-[30px] px-0">
            <img className="max-h-[237px] max-w-[256px]" src={Process1} alt="" />
            <p>
              Collect user’s needs &amp; insights
              <br />
              Understand pain points
              <br />
              Identify use cases &amp; UI requirement
              <br />
              Engage with stakeholders
            </p>
          </div>
          <div className="flex flex-[1_1_512px] flex-col items-center py-[30px] px-0">
            <img className="max-h-[237px] max-w-[256px]" src={Process2} alt="" />
            <p>
              Brainstorm as many solutions as possible
              <br />
              Establish information architecture
              <br />
              Create conceptual wireframes
            </p>
          </div>
          <div className="flex flex-[1_1_512px] flex-col items-center py-[30px] px-0">
            <img className="max-h-[237px] max-w-[256px]" src={Process3} alt="" />
            <p>
              Build a representation of the solutions
              <br />
              Tell the story of the solution
              <br />
              Evaluate design by usability tests
              <br />
              Deliver design documents
            </p>
          </div>
          <div className="flex flex-[1_1_512px] flex-col items-center py-[30px] px-0">
            <img className="max-h-[237px] max-w-[256px]" src={Process4} alt="" />
            <p>
              Build website with HTML, CSS, &amp; JS
              <br />
              Responsive design
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default AboutPage

function TimelinePointListItem({ period, event, logo }) {
  return (
    <li className="group w-[150px] odd:self-end odd:pb-[166px] even:self-start even:pt-[172px]">
      <div className="timeline-point relative flex w-[200px] flex-col items-center text-center before:absolute before:right-[46.5%] before:z-10 before:box-content before:block before:h-[6px] before:w-[6px] before:rounded-[10px] before:border-4 before:border-solid before:border-[#a77c53] before:bg-black before:content-['_'] group-odd:before:bottom-[-18px] group-even:before:top-[-16px]">
        <span className="timeline-period">{period}</span>
        <span className="timeline-event leading-none" dangerouslySetInnerHTML={{ __html: event }} />
        <img
          className="timeline-logo max-h-[65px] max-w-[150px] flex-none group-odd:pt-[10px] group-even:order-[-1] group-even:pb-[10px]"
          src={logo}
          alt=""
        />
        <svg className="group-even:order-[-2]" width="20px" height="52px" viewBox="0 0 20 52">
          <line x1="10" x2="10" y1="15" y2="55" stroke="#333333" strokeWidth="5" strokeLinecap="round" strokeDasharray="1, 10" />
        </svg>
      </div>
    </li>
  )
}

TimelinePointListItem.propTypes = {
  period: PropTypes.string.isRequired,
  event: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
}
